// src/components/LogUserActivity.js

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { API_BASE_URL } from '@/config/config';

const LogUserActivity = ({ category }) => {

   // category  client server for future 

  // Access Redux store to get the user token and userInfo
  const userInfo = useSelector((state) => state.user.userInfo);
  const token = useSelector((state) => state.user.token);

  // Get employeeID from userInfo if logged in
  const employeeID = userInfo?.EmployeeID || null;

  useEffect(() => {
    console.log('User Info:', userInfo); // Debugging line
    console.log('Employee ID:', employeeID); // Debugging line
  }, [userInfo]);

  useEffect(() => {
    // Function to log activity
    const logActivity = async (logData) => {
      try {

        //`${API_BASE_URL}users.php`
        const response = await fetch(`${API_BASE_URL}log_activity.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(logData),
        });
        const result = await response.json();
        // if (result.success) {
        //   console.log('Activity logged successfully:', result.message);
        // } else {
        //   console.error('Failed to log activity:', result.message);
        // }

        // Check if the response was successful
        if (response.ok && result.success) {
            console.log('Activity logged successfully:', result.message);
        } else {
            console.error('Failed to log activity:', result.message);
        }   

      } catch (error) {
        console.error('Error logging activity:', error.message);
        // Optionally, log the error to the backend as well
        const errorLogData = {
          employeeID,
          url: window.location.href,
          latitude: 0,
          longitude: 0,
          altitude: 0,
          ipAddress: 'N/A',
          memo: `Error logging activity: ${error.message}`,
        };
        try {
          await fetch(`${API_BASE_URL}log_activity.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(errorLogData),
          });
        } catch (err) {
          console.error('Error logging error activity:', err.message);
        }
      }
    };

    // Capture the user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude, altitude } = position.coords;

          // Get the user's IP
          try {
            const ipResponse = await fetch('https://api64.ipify.org?format=json');
            const ipData = await ipResponse.json();
            const ipAddress = ipData.ip;

            // Prepare the data to send to the backend
            const logData = {
              employeeID,
              url: window.location.href,
              latitude,
              longitude,
              altitude: altitude || 0,
              ipAddress,
              memo: `Activity logged for employee ${employeeID} at ${new Date().toISOString()}`,
            };

            // Send data to PHP backend
            await logActivity(logData);
          } catch (error) {
            console.error('Error fetching IP:', error.message);
            const logDataWithError = {
              employeeID,
              url: window.location.href,
              latitude: 0,
              longitude: 0,
              altitude: 0,
              ipAddress: 'N/A',
              memo: `Error fetching IP: ${error.message}`,
            };
            await logActivity(logDataWithError);
          }
        },
        async (error) => {
          console.error('Geolocation error:', error.message);
          const logDataWithError = {
            employeeID,
            url: window.location.href,
            latitude: 0,
            longitude: 0,
            altitude: 0,
            ipAddress: 'N/A',
            memo: `Geolocation error: ${error.message}`,
          };
          await logActivity(logDataWithError);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      const logDataWithError = {
        employeeID,
        url: window.location.href,
        latitude: 0,
        longitude: 0,
        altitude: 0,
        ipAddress: 'N/A',
        memo: 'Geolocation is not supported by this browser',
      };
      logActivity(logDataWithError);
    }
  }, [employeeID]);

  return null; // This component does not render anything
  //return <div>{employeeID ? `Logging activity for Employee ID: ${employeeID}` : 'No Employee ID available'}</div>;
};

export default LogUserActivity;
