// User-related state management

import { createSlice } from '@reduxjs/toolkit'
import { setToken as _setToken, getToken, removeToken } from '@/utils'
import { loginAPI, getProfileAPI } from '@/apis/user'

const userStore = createSlice({
  name: "user",
  // Data Status
  initialState: {
    token: getToken() || '',
    userInfo: {}
  },
  // Synchronous modification method
  reducers: {
    setToken (state, action) {
      state.token = action.payload
      _setToken(action.payload)
    },
    setUserInfo (state, action) {
      state.userInfo = action.payload
    },
    clearUserInfo (state) {
      state.token = ''
      state.userInfo = {}
      removeToken()
    }
  }
})


// Deconstructing actionCreater

const { setToken, setUserInfo, clearUserInfo } = userStore.actions

// Get the reducer function

const userReducer = userStore.reducer

// Login to get token asynchronous method encapsulation
// const fetchLogin = (loginForm) => {
//   return async (dispatch) => {
//     const res = await loginAPI(loginForm)
//     dispatch(setToken(res.data.token))
//   }
// }

// const fetchLogin = (loginForm) => {
//   return async (dispatch) => {
//     try {
//       const res = await loginAPI(loginForm); // Call the API and wait for response
//       dispatch(setToken(res.data.token));    // Dispatch the token to store
//       return res; // Return the response for further handling
//     } catch (error) {
//       throw error; // Re-throw error to be handled in the component
//     }
//   };
// };

// const fetchLogin = (loginForm) => {
//   return async (dispatch) => {
//     try {
//       const res = await loginAPI(loginForm); // Call the API
//       console.log("API Response:", res); // Debugging line
//       dispatch(setToken(res.data.token)); // Dispatch token
//       return res; // Return the response for further handling
//     } catch (error) {
//       console.error("Login Error:", error.response?.data || error.message); // Log the error
//       throw error; // Re-throw error to be handled in component
//     }
//   };
// };

// const fetchLogin = (loginForm) => {
//   return async (dispatch) => {

//     const logToLocal = (message) => {
//       const existingLogs = JSON.parse(localStorage.getItem('logs')) || [];
//       existingLogs.push(message);
//       localStorage.setItem('logs', JSON.stringify(existingLogs));
//     };

//     try {

      

//       logToLocal(`fetchLogin loginForm: ${JSON.stringify(loginForm)}`);

//       const res = await loginAPI(loginForm);
//       console.log("fetchLogin response:", res);

      
      
//       logToLocal(`fetchLogin response: ${JSON.stringify(res)}`);

//       if (res.success) {
//         dispatch(setToken(res.token));
//         return res;
//       } else {
//         throw new Error(res.message || 'Login failed');
//       }
//     } catch (error) {
//       console.error("fetchLogin error:", error);

//       logToLocal(`fetchLogin error: ${JSON.stringify(error)}`);

//       throw error; 
//     }
//   }
// }

const fetchLogin = (loginForm) => {
  return async (dispatch) => {
    try {
      const res = await loginAPI(loginForm)

      // Check if there's an error in the response
      if (res.error) {
        throw new Error(res.message); // Use the error message returned from the interceptor
      }
      
      dispatch(setToken(res.data.token))

    } catch (error) {
      // Handle error, e.g., show an error message or dispatch an error action
      console.error('Login failed:', error.message);
      return Promise.reject(error.message); // Optionally return the error to the component
    }

  }
}




// Asynchronous method for obtaining personal user information
const fetchUserInfo = () => {
  return async (dispatch) => {
    const res = await getProfileAPI()
    dispatch(setUserInfo(res.data))
  }
}

export { fetchLogin, fetchUserInfo, clearUserInfo }

export default userReducer