import './index.scss'
import React, { useRef } from 'react';
import { Card, Form, Input, Button, message, Typography } from 'antd'
import logo from '@/assets/AspectLogo.svg'
import { useDispatch } from 'react-redux'
import { fetchLogin } from '@/store/modules/user'
import { useNavigate } from 'react-router-dom'
import LogUserActivity from '@/components/LogUserActivity'; // Adjust the import path

//Destructuring Typography Components:
const { Paragraph, Text } = Typography

const Login = () => {

  const inputRef = useRef(null); // Reference for the input element

  const dispatch = useDispatch()
  const navigate = useNavigate()

  //const employeeID = 0; // Replace with actual logic to get employee ID

  const onFinish = async (values) => {
    console.log(values)
    try {
      // Triggering Asynchronous action fetchLogin
      await dispatch(fetchLogin(values))
      // 1. Skip to home page
      navigate('/')
      // 2. Prompt the user
      message.success('Login successful')
    } catch (error) {
      // Display the error message to the user (e.g., invalid credentials)
      //alert(error);  // Display error message returned from fetchLogin
      //message.error('Login error:'+ error)
      // message.error('Invalid login credentials.')
      message.error({
        content: 'Invalid login credentials.',
        duration: 2, // The message will disappear after 2 seconds
      });

      // Focus the mobile input and select the text
      // if (inputRef.current) {
      //   inputRef.current.focus();
      //   inputRef.current.select(); // Select all the text inside the input
      // }

      // Delay focusing and selecting the input until the message disappears
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.select(); // Select all the text inside the input
        }
      }, 2000); // Delay the focus until after the message disappears

    }
  }
  return (
    <div className="login">
      <Card className="login-container">
        <img className="login-logo" src={logo} alt="" />

        {/* LogUserActivity Component */}
        <LogUserActivity category="client" />

        {/* Login Form */}
        <Form onFinish={onFinish} validateTrigger="onBlur">
          <Form.Item
            name="employeeID"
            rules={[
              {
                required: true,
                message: 'Please enter your login ID',
              },
              {
                min: 5,
                message: 'Employee ID must be at least 5 characters long',
              },
              {
                pattern: /^[a-zA-Z0-9]+$/,
                message: 'Employee ID can only contain letters and numbers. No spaces or special characters allowed.',
              }
            ]}>

            <Input 
              ref={inputRef} // Attach the reference here
              size="large" 
              placeholder="Please enter your Employee ID" 
            />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: 'Please enter your password',
              },
            ]}>
            <Input size="large" placeholder="Please enter your password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
            Login
            </Button>
          </Form.Item>
          <div className="login-info">
            <Paragraph>
              <Text strong>Need Help? Please see your Senior Supervisor.</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>BID OPENS:</Text> WEDNESDAY OCT 11, 2024
            </Paragraph>
            <Paragraph>
              <Text strong>BID ENDS: &nbsp;</Text> WEDNESDAY OCT 30, 2024
            </Paragraph>
            <Paragraph>
              <Text strong><span className="highlight">30</span> Days Left</Text>
            </Paragraph>
          </div>
        </Form>
      </Card>
    </div>
  )
}

export default Login