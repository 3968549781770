// All user-related requests
import { request } from "@/utils"
// 1. Login Request

// export function loginAPI (formData) {
//   return request({
//     url: '/authorizations.php',
//     method: 'POST',
//     data: formData
//   })
// }
export async function loginAPI(formData) {
  // return request({
  //   url: '/authorizations.php',
  //   method: 'POST',
  //   data: formData
  // })
  // .then(response => {
  //   console.log("API Success:", response); // Log success response
  //   return response;
  // })
  // .catch(error => {
  //   console.error("API Error:", error.response?.data || error.message); // Log error response
  //   throw error;
  // });
  try {
    const response = await request({
      url: '/authorizations.php',
      method: 'POST',
      data: formData
    });
    console.log("API Success:", response); // Log success response
    return response;
  } catch (error) {
    console.error("API Error:", error.response?.data || error.message); // Log error response
    throw error;
  }
}


// export function loginAPI (formData) {
//   return request({
//     url: '/authorizations',
//     method: 'POST',
//     data: formData
//   })
// }

// export async function loginAPI (formData) {
//   try {
//     const response = await request({
//       url: '/authorizations.php',
//       method: 'POST',
//       data: formData
//     })
  
//     if (response.message === "OK") {
//         // Login successful
//         // Since we're using sessions, you might not need to store a token on the client
//         // If you have user info, you can store it as needed
//         console.log("Login successful");
//     }
//     return response;
//   } catch (error) {
//     console.error("Login Error:", error);
//     throw error;
//   }
// }


// 2. Get user information

// export function getProfileAPI () {
//   return request({
//     url: '/user/profile',
//     method: 'GET'
//   })
// }

export function getProfileAPI () {
  return request({
    url: '/profile.php',
    method: 'GET'
  })
}