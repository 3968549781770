import { Layout, Menu, Popconfirm } from 'antd'
import {
  HomeOutlined,
  DiffOutlined,
  EditOutlined,
  LogoutOutlined,
  UserOutlined,
  TeamOutlined,
  OrderedListOutlined,
  CloudUploadOutlined
} from '@ant-design/icons'
import './index.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInfo, clearUserInfo } from '@/store/modules/user'


const { Header, Sider } = Layout


const items = [
  {
    label: 'Home',
    key: '/',
    icon: <HomeOutlined />,
    roles: ['Admin'], // Admin only
  },
  {
    label: 'Users',
    key: '/Users',
    icon: <UserOutlined />,
    roles: ['Admin'], // Admin only
  },
  {
    label: 'Dept',
    key: '/Dept',
    icon: <EditOutlined />,
    roles: ['Admin'], // Admin only
  },
  {
    label: 'Team',
    key: '/Team',
    icon: <EditOutlined />,
    roles: ['Admin'], // Admin only
  },
  {
    label: 'Job',
    key: '/Job',
    icon: <EditOutlined />,
    roles: ['Admin'], // Admin only
  },
  {
    label: 'Upload',
    key: '/Upload',
    icon: <CloudUploadOutlined />,
    roles: ['Admin'], // Admin only
  },
  {
    label: 'Bid',
    key: '/Bid',
    icon: <OrderedListOutlined />,
    roles: ['Admin'], // Admin only
  },
  /*
  ,
  {
    label: 'Article',
    key: '/article',
    icon: <DiffOutlined />,
  },
  {
    label: 'Publish',
    key: '/publish',
    icon: <EditOutlined />,
  },
  */
  {
    label: 'JobBid',
    key: '/Jobbid',
    icon: <TeamOutlined />,
    roles: ['Admin', 'TM'], // Both roles can access
  },
]

const GeekLayout = () => {
  const navigate = useNavigate()

  // Get user role from Redux store
  const role = useSelector((state) => state.user.userInfo.role)

  // Automatically redirect "TM" users to JobBid page
  useEffect(() => {
    if (role === 'TM') {
      navigate('/Jobbid');
    }
  }, [role, navigate]);


  const onMenuClick = (route) => {
    console.log('The menu was clicked', route)
    const path = route.key
    navigate(path)
  }

  // Reverse Highlight
  // 1. Get the current route path
  const location = useLocation()
  console.log(location.pathname)
  const selectedkey = location.pathname

  // Triggering personal user information action
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(fetchUserInfo())
  }, [dispatch])

  // Logout confirmation callback
  const onConfirm = () => {
    console.log('Confirm Exit')
    dispatch(clearUserInfo())
    navigate('/login')
  }

  const name = useSelector(state => state.user.userInfo.name)

  
  const filteredItems = items.filter((item) => item.roles.includes(role))

  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
        <div className="user-info">
          <span className="user-name">{name}</span>
          <span className="user-logout">
            <Popconfirm title="Do you confirm to exit？" okText="Exit" cancelText="Cancel" onConfirm={onConfirm}>
              <LogoutOutlined /> Exit
            </Popconfirm>
          </span>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={selectedkey}
            onClick={onMenuClick}
            items={filteredItems}
            style={{ height: '100%', borderRight: 0 }}></Menu>
        </Sider>
        <Layout className="layout-content" style={{ padding: 20 }}>
          {/* Secondary routing exit */}
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  )
}
export default GeekLayout