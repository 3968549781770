import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const jobsStore = createSlice({
    name: 'jobs',
    initialState: {
        jobsList: [],
        activeIndex: 0,
        cartList: [] // cart list
    },
    reducers: {
        setJobsList(state, action) {
            // state.jobsList = action.payload;
            console.log("Payload received in setJobsList:", action.payload); // Log payload
            state.jobsList = action.payload;
            console.log("Updated state.jobsList:", state.jobsList); // Log updated state
        },
        changeActiveIndex(state, action) {
            console.log("Payload received in setJobsList:", action.payload, state.activeIndex); // Log payload
            state.activeIndex = action.payload;
            console.log("Updated state.jobsList:", state.jobsList, state.activeIndex); // Log updated state
        },
        // Add to Cart
        addCart(state, action) {
            const item = state.cartList.find(item => item.id === action.payload.id);
            if (item) {
                item.count++;
            }
            else {
                state.cartList.push(action.payload);
            }
        },
        increaseCount(state, action) {
            const item = state.cartList.find(item => item.id === action.payload.id);
            item.count++;
        },
        decreaseCount(state, action) {
            const item = state.cartList.find(item => item.id === action.payload.id);
            if (item.count === 0) {
                return;
            }
            item.count--;
        },
        clearCart(state) {
            state.cartList = [];
        }
    }
});

// Asynchronous data acquisition
const { setJobsList, changeActiveIndex, addCart, increaseCount, decreaseCount, clearCart } = jobsStore.actions;
const fetchJobsList = () => {
    return async (dispatch) => {
        //const res = await axios.get('http://localhost:3004/Jobs');
        //https://dc28.job-bid.ca/server/jobs.php
        // const res = await axios.get('https://dc28.job-bid.ca/server/jobs.php');

        // console.log("res.data:", res.data);

        // dispatch(setJobsList(res.data));
        try {
            const res = await axios.get("https://dc28.job-bid.ca/server/jobs.php");
            console.log("res.data:", res.data);
            dispatch(setJobsList(res.data));
        } catch (error) {
            console.error("Error fetching jobs:", error);
        }
    }
};

export { fetchJobsList, changeActiveIndex, addCart, increaseCount, decreaseCount, clearCart };
const jobsReducer = jobsStore.reducer;
export default jobsReducer;