// Combine redux submodules + export store instance

import { configureStore } from '@reduxjs/toolkit'
import userReducer from './modules/user'
import jobsReducer from './modules/jobsReducer'

const store = configureStore({
  reducer: {
    user: userReducer,
    jobs: jobsReducer
  }
});

export default store;